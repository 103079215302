<template>
  <v-container fluid>
    <div v-if="currentService">
      <div class="mb-3">
        <div class="text-h5">{{ currentService.name }}</div>
      </div>
      <ADashboard :modules="menu" />
    </div>
    <div v-else>
      <ALoader />
    </div>
  </v-container>
</template>

<script>
import Breadcrumb from "@/objects/breadcrumb";
import configMenu from "@/config/menu";
import { mapState } from "vuex";
import hrdcFunction from "@/objects/hrdcFunction";

export default {
  mixins: [hrdcFunction],
  computed: mapState({
    services: (state) => state.service.data.services,
    currentService: (state) => state.service.data.currentService,
    auth: (state) => state.auth.data,
  }),
  data: () => ({
    breadcrumb: new Breadcrumb(),
    service: null,
    menu: [],
  }),
  created() {
    var serviceKey = this.$route.params.serviceKey;
    if (serviceKey)
      this.service = this.$_.find(this.services, { key: serviceKey });
    this.breadcrumb.add({
      text: "Dashboard",
      to: { name: "PageConsoleDashboard" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Services",
      to: { name: "PageServiceBrowse" },
      exact: true,
    });
    this.breadcrumb.add({
      text: this.service.name,
      to: {
        name: "PageServiceDashboard",
        params: { serviceKey: this.service.key },
      },
      exact: true,
    });
    var targetMenu = configMenu[this.service.key];
    if (targetMenu) this.menu = this.$_.clone(targetMenu);

    const data = {
      roles: this.auth.roles,
      menu: this.menu,
    };

    this.configMenu(data);
  },
};
</script>
